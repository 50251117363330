import { arrayRemoveItem } from "../../utils/util";
import { luckysheetPrint } from "./print";
import Store from "../../store";

// Initialize the chart component
function print() {
  if (luckysheetPrint) {
    arrayRemoveItem(Store.asyncLoad, "print");
    Store.luckysheetPrint = luckysheetPrint;
  }
}

export { print };
